import { graphql, useStaticQuery } from "gatsby"

const useAI = () => {
  const data = useStaticQuery(graphql`
    query {
      prismicWhoWeArePage {
        data {
          body {
            ... on PrismicWhoWeArePageDataBodyLargestAi {
              slice_type
              items {
                item_description {
                  text
                }
                item_image {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        quality: 100
                        layout: CONSTRAINED
                        placeholder: BLURRED
                      )
                    }
                  }
                  alt
                }
                item_title {
                  text
                }
              }
            }
          }
        }
      }
    }
  `)
  return data.prismicWhoWeArePage.data.body.find(
    item => item.slice_type === "largest_ai"
  ).items
}

export default useAI
