import * as React from "react"

import { RichText } from "prismic-reactjs"
import Container from "~/components/ui/container"
import { GatsbyImage } from "gatsby-plugin-image"

import * as styles from "./index.module.scss"
import classNames from "classnames"

import useWhatWeDo from "~/hooks/cms/who-we-are/useWhatWeDo"

const WhatWeDoSection: React.FC = () => {
  const data = useWhatWeDo()
  return (
    <section className={styles.root}>
      <Container>
        <h2 className={classNames(styles.headline, "headline--decorated")}>
          What <span>We Do</span>
        </h2>
        <div className={styles.wrapper}>
          <div className={styles.main}>
            {data.items.map(item => {
              return (
                <div
                  key={item.item_description.text}
                  className={styles.main__item}
                >
                  <GatsbyImage
                    alt={item.item_image.alt || ""}
                    image={
                      item.item_image.localFile.childImageSharp.gatsbyImageData
                    }
                    className={styles.main__img}
                    objectFit="contain"
                    objectPosition="50% 50%"
                  />
                  <p className={styles.main__paragraph}>
                    {item.item_description.text}
                  </p>
                </div>
              )
            })}
          </div>
          <div className={styles.info}>
            <RichText render={data.primary.text.raw} />
          </div>
        </div>
      </Container>
    </section>
  )
}

export default WhatWeDoSection
