// extracted by mini-css-extract-plugin
export var animation = "index-module--animation--ad147";
export var animation__animated = "index-module--animation__animated--2e474";
export var containerMob = "index-module--container-mob--b49b3";
export var headline = "index-module--headline--53394";
export var info = "index-module--info--cdba5";
export var info__headline = "index-module--info__headline--02bb1";
export var info__img = "index-module--info__img--6e00e";
export var info__item = "index-module--info__item--8b992";
export var info__paragraph = "index-module--info__paragraph--bf04a";
export var info__text = "index-module--info__text--56877";
export var main = "index-module--main--2a176";
export var main__img = "index-module--main__img--258d0";
export var main__paragraph = "index-module--main__paragraph--5a0d2";
export var main__wrapper = "index-module--main__wrapper--1f19c";
export var root = "index-module--root--775b4";
export var wrapper = "index-module--wrapper--a328a";