import { graphql, useStaticQuery } from "gatsby"

const useMission = () => {
  const data = useStaticQuery(graphql`
    query {
      prismicWhoWeArePage {
        data {
          body {
            ... on PrismicWhoWeArePageDataBodyMission {
              slice_type
              primary {
                mission {
                  raw
                }
                title {
                  text
                }
              }
            }
          }
        }
      }
    }
  `)

  return data.prismicWhoWeArePage.data.body.find(
    item => item.slice_type === "mission"
  ).primary
}

export default useMission
