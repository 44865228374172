import * as React from "react"
import { PageProps } from "gatsby"

import Layout from "~/components/layout"
import SEO from "~/components/seo"
import HeroSection from "~/components/sections/who-we-are/hero"
import FutureSection from "~/components/sections/who-we-are/future"
import WhatWeDoSection from "~/components/sections/who-we-are/what-we-do"
import LargestAISection from "~/components/sections/who-we-are/largest-ai"
import GlobalOrganizationSection from "~/components/sections/who-we-are/global-organization"
import MissionSection from "~/components/sections/who-we-are/mission"

const WhoWeAre: React.FC<PageProps> = () => (
  <Layout>
    <SEO title="Who We Are" />
    <HeroSection />
    <FutureSection />
    <WhatWeDoSection />
    <LargestAISection />
    <GlobalOrganizationSection />
    <MissionSection />
  </Layout>
)

export default WhoWeAre
