import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { RichText } from "prismic-reactjs"

import * as styles from "./index.module.scss"
import useMission from "~/hooks/cms/who-we-are/useMission"

const MissionSection: React.FC = () => {
  const primary = useMission()
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "who-we-are/flag.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
    }
  `)
  return (
    <section className={styles.root}>
      <div className={styles.main}>
        <div className={styles.main__text}>
          <p>
            The Heart of <span>Urus</span>
          </p>
          <h2 className={styles.main__headline}>
            Urus <span>Mission</span>
          </h2>
          <p>{primary.title.text}</p>
          <RichText render={primary.mission.raw} />
        </div>
        <div className={styles.main__img}>
          <GatsbyImage
            alt="Urus flag"
            image={data.file.childImageSharp.gatsbyImageData}
            objectFit="cover"
            objectPosition="50% 50%"
          />
        </div>
      </div>
    </section>
  )
}

export default MissionSection
