import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { useInView } from "react-intersection-observer"
import classNames from "classnames"

import Container from "~/components/ui/container"
import Globe from "~/images/who-we-are/globe.svg"
import Leader from "~/images/who-we-are/leader.svg"
import Cow from "~/images/who-we-are/cow.svg"

import * as styles from "./index.module.scss"
import useAI from "~/hooks/cms/who-we-are/useAI"

const LargestAISection: React.FC = () => {
  const items = useAI()
  const [isAnimated, setIsAnimated] = React.useState(false)
  const { ref, inView } = useInView({ delay: 800 })
  const cowData = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "who-we-are/cow.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
    }
  `)
  React.useEffect(() => {
    if (inView) {
      setIsAnimated(true)
    }
  }, [setIsAnimated, inView])
  return (
    <section className={styles.root}>
      <Container>
        <h2 className={styles.headline}>
          <span>Largest A.I.</span> In The World
        </h2>
      </Container>
      <div className={styles.wrapper}>
        <div className={styles.main}>
          <div
            ref={ref}
            className={classNames(styles.animation, {
              [styles.animation__animated]: isAnimated,
            })}
          ></div>
          <div className={styles.main__img}>
            <GatsbyImage
              alt="Cow"
              image={cowData.file.childImageSharp.gatsbyImageData}
              objectFit="cover"
              objectPosition="50% 50%"
            />
          </div>
          <div className={styles.main__wrapper}>
            <p className={styles.main__paragraph}>We Inseminate</p>
            <p className={styles.main__paragraph}>1 cow</p>
            <p className={styles.main__paragraph}>every second</p>
            <p className={styles.main__paragraph}>Around the globe</p>
          </div>
        </div>
        <div className={styles.containerMob}>
          <div className={styles.info}>
            {items.map(item => {
              return (
                <div key={item.item_title.text} className={styles.info__item}>
                  <GatsbyImage
                    alt={item.item_image.alt || ""}
                    image={
                      item.item_image.localFile.childImageSharp.gatsbyImageData
                    }
                    className={styles.info__img}
                    objectFit="contain"
                    objectPosition="50% 50%"
                  />
                  <div className={styles.info__text}>
                    <h3 className={styles.info__headline}>
                      {item.item_title.text}
                    </h3>
                    <p className={styles.info__paragraph}>
                      {item.item_description.text}
                    </p>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </section>
  )
}

export default LargestAISection
