import { graphql, useStaticQuery } from "gatsby"

const useHero = () => {
  const data = useStaticQuery(graphql`
    query {
      prismicWhoWeArePage {
        data {
          body {
            ... on PrismicWhoWeArePageDataBodyHeroSection {
              slice_type
              primary {
                headline {
                  text
                }
              }
            }
          }
        }
      }
    }
  `)

  return data.prismicWhoWeArePage.data.body.find(
    item => item.slice_type === "hero_section"
  ).primary
}

export default useHero
