import { graphql, useStaticQuery } from "gatsby"

const useFuture = () => {
  const data = useStaticQuery(graphql`
    query {
      prismicWhoWeArePage {
        data {
          body {
            ... on PrismicWhoWeArePageDataBodyFuture {
              slice_type
              primary {
                image {
                  alt
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        quality: 100
                        layout: CONSTRAINED
                        placeholder: BLURRED
                      )
                    }
                  }
                }
              }
              items {
                item {
                  text
                }
              }
            }
          }
        }
      }
    }
  `)
  return data.prismicWhoWeArePage.data.body.find(
    item => item.slice_type === "future"
  )
}

export default useFuture
