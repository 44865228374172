import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import Container from "~/components/ui/container"

import * as styles from "./index.module.scss"

import useHero from "~/hooks/cms/who-we-are/useHero"

const HeroSection: React.FC = () => {
  const { headline } = useHero()
  const data = useStaticQuery(graphql`
    query {
      desktop: file(relativePath: { eq: "who-we-are/hero.png" }) {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            layout: FULL_WIDTH
            placeholder: BLURRED
          )
        }
      }
      mobile: file(relativePath: { eq: "who-we-are/hero_mobile.png" }) {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            layout: FULL_WIDTH
            placeholder: BLURRED
          )
        }
      }
    }
  `)
  return (
    <section className={styles.root}>
      <GatsbyImage
        alt="Worker"
        image={data.desktop.childImageSharp.gatsbyImageData}
        className={styles.imgDesktop}
        objectFit="cover"
        objectPosition="50% 50%"
      />
      <GatsbyImage
        alt="Worker"
        image={data.mobile.childImageSharp.gatsbyImageData}
        className={styles.imgMobile}
        objectFit="cover"
        objectPosition="50% 50%"
      />
      <Container>
        <h1 className={styles.headline}>{headline.text}</h1>
      </Container>
    </section>
  )
}

export default HeroSection
