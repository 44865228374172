import { graphql, useStaticQuery } from "gatsby"

const useWhatWeDo = () => {
  const data = useStaticQuery(graphql`
    query {
      prismicWhoWeArePage {
        data {
          body {
            ... on PrismicWhoWeArePageDataBodyWhatWeDo {
              id
              items {
                item_description {
                  text
                }
                item_image {
                  alt
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        quality: 100
                        layout: CONSTRAINED
                        placeholder: BLURRED
                      )
                    }
                  }
                  alt
                }
              }
              primary {
                text {
                  raw
                }
              }
              slice_type
            }
          }
        }
      }
    }
  `)

  return data.prismicWhoWeArePage.data.body.find(
    item => item.slice_type === "what_we_do"
  )
}

export default useWhatWeDo
