import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import Container from "~/components/ui/container"

import * as styles from "./index.module.scss"

import useFuture from "~/hooks/cms/who-we-are/useFuture"

const FutureSection: React.FC = () => {
  const data = useFuture()
  return (
    <section className={styles.root}>
      <Container>
        <div className={styles.wrapper}>
          <div className={styles.innerWrapper}>
            <div className={styles.info}>
              <h3 className={styles.subheadline}>URUS IS:</h3>
              <ul className={styles.list}>
                {data.items.map(({ item }) => (
                  <li key={item.text}>{item.text}</li>
                ))}
              </ul>
            </div>
            <div className={styles.main}>
              <h2 className={styles.headline}>
                Focusing <br className={styles.lineBreak} />
                <span>on the future</span>
              </h2>
              <div className={styles.imgWrapper}>
                <GatsbyImage
                  alt={data.primary.image.alt || ""}
                  image={
                    data.primary.image.localFile.childImageSharp.gatsbyImageData
                  }
                  className={styles.img}
                  objectFit="cover"
                  objectPosition="50% 50%"
                />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </section>
  )
}

export default FutureSection
