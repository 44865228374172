// extracted by mini-css-extract-plugin
export var headline = "index-module--headline--fcedb";
export var img = "index-module--img--27887";
export var imgWrapper = "index-module--img-wrapper--16497";
export var info = "index-module--info--11637";
export var innerWrapper = "index-module--inner-wrapper--78801";
export var lineBreak = "index-module--line-break--a37c7";
export var list = "index-module--list--4fe88";
export var main = "index-module--main--6679e";
export var root = "index-module--root--90049";
export var subheadline = "index-module--subheadline--61cce";
export var wrapper = "index-module--wrapper--93f1d";