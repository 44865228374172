import * as React from "react"

import Container from "~/components/ui/container"
import { GatsbyImage } from "gatsby-plugin-image"

import * as styles from "./index.module.scss"
import classNames from "classnames"
import useOrganization from "~/hooks/cms/who-we-are/useOrganization"
import { graphql, useStaticQuery } from "gatsby"

const GlobalOrganizationSection: React.FC = () => {
  const items = useOrganization()
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "who-we-are/map.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
    }
  `)
  return (
    <section className={styles.root}>
      <Container>
        <div className={styles.wrapper}>
          <h2 className={classNames("headline--decorated", styles.headline)}>
            <span>A global</span> <br className={styles.lineBreak} />
            organization
          </h2>
          <GatsbyImage
            alt="Map"
            image={data.file.childImageSharp.gatsbyImageData}
            objectFit="contain"
            objectPosition="50% 50%"
            className={styles.img}
          />
          <ul className={styles.list}>
            {items.map(({ item }) => (
              <li key={item.text} className={styles.listItem}>
                {item.text}
              </li>
            ))}
          </ul>
        </div>
      </Container>
    </section>
  )
}

export default GlobalOrganizationSection
