import { graphql, useStaticQuery } from "gatsby"

const useOrganization = () => {
  const data = useStaticQuery(graphql`
    query {
      prismicWhoWeArePage {
        data {
          body {
            ... on PrismicWhoWeArePageDataBodyGlobalOrganization {
              items {
                item {
                  text
                }
              }
              slice_type
            }
          }
        }
      }
    }
  `)

  return data.prismicWhoWeArePage.data.body.find(
    item => item.slice_type === "global_organization"
  ).items
}

export default useOrganization
